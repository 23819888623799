import ReactGTM from "react-gtm-module";

function createInitializeGTM(trackingCode) {
    let initialized = false;

    /**
     * Initializes google tag manager.
     */
    return function initializeGTM() {
        if (trackingCode) {
            if (!initialized) {
                ReactGTM.initialize({
                    gtmId: trackingCode,
                    dataLayerName: "dataLayer",
                });
                initialized = true;
            }
        }
    };
}

export default createInitializeGTM(process.env.GATSBY_GA_TRACKING_CODE);
