exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-contact-index-jsx": () => import("./../../../src/pages/contact/index.jsx" /* webpackChunkName: "component---src-pages-contact-index-jsx" */),
  "component---src-pages-cookie-policy-index-jsx": () => import("./../../../src/pages/cookie-policy/index.jsx" /* webpackChunkName: "component---src-pages-cookie-policy-index-jsx" */),
  "component---src-pages-customers-index-jsx": () => import("./../../../src/pages/customers/index.jsx" /* webpackChunkName: "component---src-pages-customers-index-jsx" */),
  "component---src-pages-disclaimer-index-jsx": () => import("./../../../src/pages/disclaimer/index.jsx" /* webpackChunkName: "component---src-pages-disclaimer-index-jsx" */),
  "component---src-pages-dna-index-jsx": () => import("./../../../src/pages/dna/index.jsx" /* webpackChunkName: "component---src-pages-dna-index-jsx" */),
  "component---src-pages-general-terms-index-jsx": () => import("./../../../src/pages/general-terms/index.jsx" /* webpackChunkName: "component---src-pages-general-terms-index-jsx" */),
  "component---src-pages-group-index-jsx": () => import("./../../../src/pages/group/index.jsx" /* webpackChunkName: "component---src-pages-group-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-innovation-index-jsx": () => import("./../../../src/pages/innovation/index.jsx" /* webpackChunkName: "component---src-pages-innovation-index-jsx" */),
  "component---src-pages-privacy-statement-index-jsx": () => import("./../../../src/pages/privacy-statement/index.jsx" /* webpackChunkName: "component---src-pages-privacy-statement-index-jsx" */),
  "component---src-pages-products-and-brands-bru-fabrics-jsx": () => import("./../../../src/pages/products-and-brands/bru-fabrics.jsx" /* webpackChunkName: "component---src-pages-products-and-brands-bru-fabrics-jsx" */),
  "component---src-pages-products-and-brands-fibre-guard-jsx": () => import("./../../../src/pages/products-and-brands/fibre-guard.jsx" /* webpackChunkName: "component---src-pages-products-and-brands-fibre-guard-jsx" */),
  "component---src-pages-products-and-brands-fr-one-jsx": () => import("./../../../src/pages/products-and-brands/fr-one.jsx" /* webpackChunkName: "component---src-pages-products-and-brands-fr-one-jsx" */),
  "component---src-pages-products-and-brands-index-jsx": () => import("./../../../src/pages/products-and-brands/index.jsx" /* webpackChunkName: "component---src-pages-products-and-brands-index-jsx" */),
  "component---src-pages-products-and-brands-products-jsx": () => import("./../../../src/pages/products-and-brands/products.jsx" /* webpackChunkName: "component---src-pages-products-and-brands-products-jsx" */),
  "component---src-pages-products-and-brands-twinbru-jsx": () => import("./../../../src/pages/products-and-brands/twinbru.jsx" /* webpackChunkName: "component---src-pages-products-and-brands-twinbru-jsx" */),
  "component---src-pages-quality-index-jsx": () => import("./../../../src/pages/quality/index.jsx" /* webpackChunkName: "component---src-pages-quality-index-jsx" */),
  "component---src-pages-social-investments-index-jsx": () => import("./../../../src/pages/social-investments/index.jsx" /* webpackChunkName: "component---src-pages-social-investments-index-jsx" */),
  "component---src-pages-solutions-index-jsx": () => import("./../../../src/pages/solutions/index.jsx" /* webpackChunkName: "component---src-pages-solutions-index-jsx" */),
  "component---src-pages-sustainability-economic-growth-jsx": () => import("./../../../src/pages/sustainability/economic-growth.jsx" /* webpackChunkName: "component---src-pages-sustainability-economic-growth-jsx" */),
  "component---src-pages-sustainability-index-jsx": () => import("./../../../src/pages/sustainability/index.jsx" /* webpackChunkName: "component---src-pages-sustainability-index-jsx" */),
  "component---src-pages-sustainability-partnerships-jsx": () => import("./../../../src/pages/sustainability/partnerships.jsx" /* webpackChunkName: "component---src-pages-sustainability-partnerships-jsx" */),
  "component---src-pages-sustainability-responsible-production-jsx": () => import("./../../../src/pages/sustainability/responsible-production.jsx" /* webpackChunkName: "component---src-pages-sustainability-responsible-production-jsx" */),
  "component---src-pages-sustainability-take-climate-action-index-jsx": () => import("./../../../src/pages/sustainability/take-climate-action/index.jsx" /* webpackChunkName: "component---src-pages-sustainability-take-climate-action-index-jsx" */),
  "component---src-pages-sustainability-take-climate-action-operations-jsx": () => import("./../../../src/pages/sustainability/take-climate-action/operations.jsx" /* webpackChunkName: "component---src-pages-sustainability-take-climate-action-operations-jsx" */),
  "component---src-pages-sustainability-take-climate-action-products-jsx": () => import("./../../../src/pages/sustainability/take-climate-action/products.jsx" /* webpackChunkName: "component---src-pages-sustainability-take-climate-action-products-jsx" */),
  "component---src-pages-sustainability-take-climate-action-transport-jsx": () => import("./../../../src/pages/sustainability/take-climate-action/transport.jsx" /* webpackChunkName: "component---src-pages-sustainability-take-climate-action-transport-jsx" */),
  "component---src-pages-sustainability-traceability-transparency-jsx": () => import("./../../../src/pages/sustainability/traceability-transparency.jsx" /* webpackChunkName: "component---src-pages-sustainability-traceability-transparency-jsx" */)
}

