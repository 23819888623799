/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import "@/styles/index.scss";

import initializeGTM from "@/utils/initializeGTM";

export const onClientEntry = () => {
    if (window.CSS === undefined) {
        window.CSS = {
            escape(ident) {
                return ident;
            },
            supports() {
                return false;
            },
        };
    }

    // Load lazysizes on browsers that do not support lazy loading
    if (!("loading" in HTMLImageElement.prototype)) {
        import("lazysizes");
    }

    initializeGTM();
};

const ON_ROUTE_CHANGE_EVENT = "route-changed";

export const onRouteUpdate = () => {
    const dataLayer = window.dataLayer;

    // GTM Not initialized yet or cookies have been declined.
    if (dataLayer === undefined) {
        return;
    }

    // Make sure titles have been updated properly
    // (Copied from https://github.com/gatsbyjs/gatsby/blob/49fdb53ecd1857b67d7bb078bcd2bb42e352b7cb/packages/gatsby-plugin-google-tagmanager/src/gatsby-browser.js#L6)
    setTimeout(() => {
        dataLayer.push({ event: ON_ROUTE_CHANGE_EVENT });
    }, 50);
};
